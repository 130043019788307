import { InferType, number, object, string } from 'yup'
import { DonationRecurrenceInterval } from '~/types/graphql'

const schema = object({
  amount: number().required('Donation amount is required'),
  paymentFrequency: string()
    .oneOf([
      DonationRecurrenceInterval.None,
      DonationRecurrenceInterval.Monthly,
      DonationRecurrenceInterval.Quarterly,
      DonationRecurrenceInterval.Yearly,
    ])
    .defined(),
  allocationId: string(),
}).defined()

export type Values = Required<InferType<typeof schema>>

export const useForm = () => {
  // TODO: manage initialData here
  return { schema } as const
}
