import { Button } from 'reakit/Button'
import { Link, LinkProps } from '~/components/link'

import IconBack from './assets/icon-back.svg'
import { StyledMeta } from './style'

type ButtonProps = {
  onClick: () => void
}

type Props = {
  children: string
} & (Omit<LinkProps, 'children'> | ButtonProps)

export const BackButton = ({ children, ...rest }: Props) => {
  if ('href' in rest) {
    return (
      <Link {...rest}>
        <StyledMeta as="a">
          <IconBack style={{ marginRight: 18 }} />
          {children}
        </StyledMeta>
      </Link>
    )
  }

  return (
    <Button {...rest}>
      <StyledMeta>
        <IconBack style={{ marginRight: 18 }} />
        {children}
      </StyledMeta>
    </Button>
  )
}
