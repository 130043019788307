import { useFormikContext } from 'formik'
import { Amount } from '~/components/amount'
import { Button } from '~/components/button'
import { Box, Flex } from '~/components/layout'
import { Meta } from '~/components/typography'

import { ChariotFormValues } from '../../hooks/use-form'

type Props = {
  amount: number
}

export const ChariotSummary = ({ amount }: Props) => {
  const { isSubmitting, isValid } = useFormikContext<ChariotFormValues>()

  return (
    <Box
      padding={['30px 20px 40px', '30px 20px 40px', 40]}
      style={{ background: '#F7F7F7' }}
    >
      <Flex justifyContent="space-between" marginBottom={16}>
        <Meta>Total Charge</Meta>
        <Meta>
          <Amount>{amount}</Amount>
        </Meta>
      </Flex>
      <Button
        disabled={!isValid}
        type="submit"
        width="100%"
        loading={isSubmitting}
      >
        Donate Now
      </Button>
    </Box>
  )
}
