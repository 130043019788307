import { RadioGroup as ReakitRadioGroup } from 'reakit/Radio'
import styled from 'styled-components'
import { AmountInput } from '~/components/amount-input'
import { Button } from '~/components/button'
import { Box } from '~/components/layout'
import { colors } from '~/styles/colors'
import { media } from '~/styles/media'

export const AmountButton = styled(Button)<{ isSelected: boolean }>(props => ({
  width: '6.375rem',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: props.isSelected ? colors.primary.default : colors.white,
  border: `1px solid ${
    props.isSelected ? colors.primary.default : colors.whiteFog
  }`,
  color: props.isSelected ? colors.light : colors.dark,
  borderRadius: '0px',
  borderRight: 0,
  '&:first-child': {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  '&:last-child': {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderRight: `1px solid ${
      props.isSelected ? colors.primary.default : colors.whiteFog
    }`,
  },
  [media.maxMedium]: {
    width: '4.25rem',
    flexGrow: 1,
  },
}))

export const RadioGroup = styled(ReakitRadioGroup)({
  [media.small]: {
    display: 'flex',
  },
})

export const Label = styled.label({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.875rem',
  lineHeight: 1.7,
  color: colors.dark,
  cursor: 'pointer',
  flex: 1,
  height: 48,
})

export const SelectDonationSectionWrapper = styled(Box)({
  // borderBottom: '1px solid #CFD8E5',
  padding: '2.5rem',

  [media.maxSmall]: {
    padding: '1rem 1.25rem 2.5rem 1.25rem',
  },
})

export const SubmitBtnWrapper = styled(Box)({
  padding: '0 2.5rem 2.5rem 2.5rem',
  display: 'flex',
  gap: '0.75rem',
  flexDirection: 'column',

  [media.maxSmall]: {
    padding: '0 1.25rem 3rem 1.25rem',
  },
})

export const PaymentRecurrenceSelectWrapper = styled(Box)({
  width: '8.375rem',
  marginLeft: '20',
  [media.maxMedium]: {
    display: 'none',
  },
})

export const PaymentRecurrenceSelectMobileWrapper = styled(Box)({
  [media.maxMedium]: {
    width: '100%',
    display: 'block',
  },
  display: 'none',
})

export const AmountInputField = styled(AmountInput)({
  [media.maxMedium]: {
    marginBottom: '1rem',
  },
})
export const AmountButtonsWrapper = styled(Box)({
  [media.maxMedium]: {
    width: '100%',
  },
  display: 'flex',
  flexDirection: 'row',
})

export const ButtonBody = styled.div({
  display: 'flex',
  gap: '0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
  height: '3rem',
})
