import { useElements, useStripe } from '@stripe/react-stripe-js'
import {
  PaymentIntent,
  PaymentRequestPaymentMethodEvent,
  StripeError,
} from '@stripe/stripe-js'
import {
  CreateOrderInput,
  DonationRecurrenceInterval,
  PageNonprofit,
  StripePaymentMethodType,
} from '~/types/graphql'

import { useCreateOrderMutation } from '../graphql/create-order'
import { PaymentMethod } from '../types'
import { getDoubleTheDonationValues, registerDoubleTheDonation } from '../utils'
import { Values } from './use-form'
import { useUtmParse } from './use-utm-parse'

type Options = {
  pageId: string
  onSuccess: (amount: number, orderId: string) => void
  fundraiserId?: string
  nonprofit: PageNonprofit
}
enum Status {
  Succeeded = 'succeeded',
  Processing = 'processing',
}

export const useCreateOrder = ({
  pageId,
  fundraiserId,
  onSuccess,
  nonprofit,
}: Options) => {
  const stripe = useStripe()
  const elements = useElements()

  const utm = useUtmParse()
  const [createOrderMutation] = useCreateOrderMutation()

  const createOrder = async (
    recaptchaResponse: string,
    values: Values,
    _event?: PaymentRequestPaymentMethodEvent
  ) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    const { error: submitError } = await elements.submit()
    if (submitError) {
      return
    }

    let clientSecret: string
    let orderId: string
    let paymentMethod =
      values.paymentMethod == PaymentMethod.Ach
        ? StripePaymentMethodType.BankAccount
        : StripePaymentMethodType.Card

    const orderAmount = values.coverProcessingFees
      ? values.orderCalculation.withCoveringFees
      : values.orderCalculation.withoutCoveringFees

    try {
      const input: CreateOrderInput = {
        pageId,
        recaptchaResponse,
        email: values.email.trim(),
        anonymous: values.anonymous,
        donorCoversProcessingFees: values.coverProcessingFees,
        amount: values.amount,
        recurrenceInterval: values.switchedToMonthly
          ? DonationRecurrenceInterval.Monthly
          : values.paymentFrequency,
        // TODO: remove tip from crete mutation?
        tip: 0,
        utm,
        campaignId: utm.campaign,
        fundraiserId,
        allocationId: values.allocationId,
        address: {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          ...values.address,
        },
        stripePaymentMethod: paymentMethod,
      }

      if (values.showDedication) {
        input.dedication = {
          type: values.dedication.type,
          firstName: values.dedication.firstName,
          lastName: values.dedication.lastName,
        }

        if (values.dedication.notification?.email) {
          input.dedication.notification = values.dedication.notification
        }
      }

      if (nonprofit.doubleTheDonationPublicKey) {
        const {
          doubleTheDonationCompanyId,
          doubleTheDonationEnteredText,
          doubleTheDonationStatus,
        } = getDoubleTheDonationValues()

        input.doubleTheDonationCompanyId = String(doubleTheDonationCompanyId)
        input.doubleTheDonationEnteredText = doubleTheDonationEnteredText
        input.doubleTheDonationStatus = doubleTheDonationStatus
      }

      const { data } = await createOrderMutation({ variables: { input } })
      clientSecret = data!.createOrder.clientSecret
      orderId = data!.createOrder.id
    } catch (error: any) {
      throw new Error(
        (error as any)?.graphQLErrors?.[0]?.message ?? 'Something Went Wrong'
      )
    }

    let result: { paymentIntent?: PaymentIntent; error?: StripeError }

    result = await stripe!.confirmPayment({
      elements,
      clientSecret,
      redirect: 'if_required',
      confirmParams: {
        // URL not in use, required by stripe
        return_url: 'https://kindest.com',
      },
    })

    const { error, paymentIntent } = result
    if (error) {
      throw new Error(error.message)
    }
    if (
      paymentIntent?.status == Status.Succeeded ||
      paymentIntent?.status == Status.Processing
    ) {
      if (nonprofit.doubleTheDonationPublicKey) {
        registerDoubleTheDonation({
          publicKey: nonprofit.doubleTheDonationPublicKey,
          pageId,
          orderId,
          amount: orderAmount.net,
          values,
        })
      }
      onSuccess(orderAmount.net, orderId)
    }
  }

  return createOrder
}
