import { memo, useEffect } from 'react'
import useScript from 'react-script-hook'
import { Meta } from '~/components/typography'

import { DoubleTheDonationInput } from './style'

type Props = {
  publicKey: string
}

export const DoubleTheDonation = memo(({ publicKey }: Props) => {
  const [loadingScript] = useScript({
    src: 'https://doublethedonation.com/api/js/ddplugin.js',
    checkForExisting: true,
    async: true,
  })

  useEffect(() => {
    ;(window as any).DDCONF = { API_KEY: publicKey }
  }, [])

  useEffect(() => {
    if (!loadingScript) {
      if ((window as any).doublethedonation) {
        ;(window as any).doublethedonation.plugin.load_streamlined_input()
      }
    }
  }, [loadingScript])

  return (
    <>
      <Meta marginBottom={10}>
        See If Your Employer Will Match Your Donation
      </Meta>
      <DoubleTheDonationInput id="dd-company-name-input" />
    </>
  )
})
