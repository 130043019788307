import { useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Values } from './use-form';

type Options = {
  values: Values
  onPaymentMethod: (event: PaymentRequestPaymentMethodEvent) => void
}

export const usePaymentRequest = ({ values, onPaymentMethod }: Options) => {
  const stripe = useStripe()
  const [paymentRequestMethod, setPaymentRequestMethod] = useState<
    'Apple Pay' | 'Other Method'
  >()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  const handlerRef = useRef<(event: PaymentRequestPaymentMethodEvent) => void>()

  const activeFees = useMemo(() => {
    return values.coverProcessingFees
      ? values.orderCalculation.withCoveringFees
      : values.orderCalculation.withoutCoveringFees
  }, [values.coverProcessingFees])

  useEffect(() => {
    handlerRef.current = onPaymentMethod
  }, [onPaymentMethod])

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!paymentRequest) {
      const newPaymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total Charge',
          amount: activeFees.gross,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      newPaymentRequest.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(newPaymentRequest)
          setPaymentRequestMethod(
            result.applePay ? 'Apple Pay' : 'Other Method'
          )
        }
      })

      newPaymentRequest.on('paymentmethod', event => {
        handlerRef.current?.(event)
      })
    } else {
      paymentRequest.update({
        total: {
          label: 'Total Charge',
          amount: activeFees.gross,
        },
      })
    }
  }, [stripe, paymentRequest, activeFees])

  return { paymentRequest, paymentRequestMethod } as const
}
