import styled from 'styled-components'
import { Box, Flex } from '~/components/layout'
import { Meta } from '~/components/typography'
import { colors } from '~/styles/colors'
import { media } from '~/styles/media'

export const MetaWrapper = styled(Meta)({
  [media.maxSmall]: {
    padding: '0 1.25rem 3rem 1.25rem',
  },

  maxWidth: '46rem',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '0 auto',
})

export const HeaderColorStripe = styled(Box)({
  borderBottom: colors.border,
  background: colors.primary.default,
  [media.medium]: {
    background: colors.primary.default,
    paddingTop: '2.5rem',
  },
})

export const HeaderContent = styled(Flex)({
  maxWidth: '46rem',
  padding: '2.5rem',
  margin: '0 auto',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px solid #CFD8E5',
  borderRadius: ' 0.375rem 0.375rem 0 0',
  borderBottom: '0',
  background: colors.white,

  [media.maxLarge]: {
    borderRadius: '0',
    borderTop: '0',
    padding: '3rem 1.25rem 1.5rem 1.25rem ',
  },
})

export const StepWrapper = styled(Box)({
  maxWidth: '46rem',
  margin: '0 auto',
  border: '1px solid #CFD8E5',
  borderRadius: '0 0 0.375rem 0.375rem',
  borderTop: '0',
  [media.maxSmall]: {
    border: '0',
  },
})

export const ChariotConfirmBanner = styled(Box)({
  height: '3rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: colors.primary.ultralight,
  marginTop: '1.5rem',
  '> div': {
    color: colors.primary.default,
  },
})
