import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'

export const DoubleTheDonationInput = styled.div({
  '& .dtd-streamlined-plugin': {
    fontSize: '0.875rem',
    lineHeight: 1.7,
    color: colors.dark,
  },

  '& .dtd-search-input': {
    fontSize: '0.875rem',
    lineHeight: 1.7,
    border: colors.border,
    borderRadius: 2,
    padding: '15px 18px',

    '::placeholder': {
      color: colors.slateGray,
      fontWeight: fonts.text.regular,
    },
  },

  '& .dd-company-selected': {
    border: colors.border,
    borderRadius: 2,
    padding: '11px 18px',
    display: 'flex',
  },

  ' & .dd-company-selected .dtd-company-selected-name': {
    fontWeight: fonts.text.medium,
    marginLeft: 4,
  },

  '& .wrongcompany': {
    marginLeft: 'auto',
    color: colors.primary.default,
  },
})
