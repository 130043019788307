import { useFormikContext } from 'formik'
import { Box, Grid } from '~/components/layout'
import { Radio } from '~/components/radio'
import { TextInputField } from '~/components/text-input'
import { TextareaField } from '~/components/textarea'
import { colors } from '~/styles/colors'
import { DedicationType } from '~/types/graphql'

import { Values } from '../../hooks/use-form'

type Props = {}

export const Dedication = ({}: Props) => {
  const { values, setFieldValue } = useFormikContext<Values>()

  if (values.showDedication === false) {
    return null
  }

  const optionalNotification =
    values.dedication.notification?.email === '' &&
    values.dedication.notification.firstName === '' &&
    values.dedication.notification.lastName === ''

  return (
    <Box
      padding={[20, 40]}
      background={colors.soft}
      style={{
        borderBottom: '1px solid #CFD8E5',
      }}
    >
      <Box marginBottom={20}>
        <Radio
          ariaLabel="Dedication"
          defaultValue={values.dedication.type}
          items={[
            { label: 'In honor of...', value: DedicationType.InHonor },
            { label: 'In memory of...', value: DedicationType.InMemory },
          ]}
          onChange={value => setFieldValue('dedication.type', value)}
        />
      </Box>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={20}>
        <TextInputField
          label="Honoree's First Name"
          name="dedication.firstName"
        />
        <TextInputField
          label="Honoree's Last Name"
          name="dedication.lastName"
        />
        <TextareaField
          label="Dedication Message"
          name="dedication.notification.message"
          optional
          minRows={3}
          gridColumn={[null, '1 / 3']}
        />
        <TextInputField
          label="Notification Email Address "
          name="dedication.notification.email"
          gridColumn={[null, '1 / 3']}
          optional={optionalNotification}
          autoComplete="email"
        />
        <TextInputField
          label="Recipient's First Name"
          name="dedication.notification.firstName"
          optional={optionalNotification}
        />
        <TextInputField
          label="Recipient's Last Name"
          name="dedication.notification.lastName"
          optional={optionalNotification}
        />
      </Grid>
    </Box>
  )
}
