import { ChariotError, CreateOrderChariotInput } from '~/types/graphql'

import { useCreateOrderChariotMutation } from '../graphql/create-order'
import { Values } from './use-form'
import { useUtmParse } from './use-utm-parse'

type ChariotResponse = {
  workflowSessionId: string
  userFriendlyId: string
  fundId: string
  amount: number
}

type Options = {
  pageId: string
  onSuccess: (amount: number, orderId: string) => void
  onError: (error: ChariotError) => void
  fundraiserId?: string
}

export const useCreateOrderChariot = ({
  pageId,
  fundraiserId,
  onSuccess,
  onError,
}: Options) => {
  const utm = useUtmParse()
  const [createOrderCharitoMutation] = useCreateOrderChariotMutation()

  const createOrderChariot = async (
    values: Values,
    chariotResponse: ChariotResponse,
    recaptchaResponse: string
  ) => {
    const {
      allocationId,
      email,
      firstName,
      lastName,
      phone,
      showDedication,
      dedication,
      // TODO: https://github.com/kindestcom/automator-web/issues/193
      // Currently, we don't allow to cover processing fees for Chariot donations. However, it may be used in the
      // future. The logic is already implemented both in the backend and frontend. So in case we need it, we can use it.
      // coverProcessingFees,
      anonymous,
      // TODO: collect address here or from Chariot? | address,
    } = values
    const { fundId, userFriendlyId, workflowSessionId, amount } =
      chariotResponse

    try {
      const input: CreateOrderChariotInput = {
        pageId,
        utm: utm,
        campaignId: utm.campaign,
        fundraiserId,
        allocationId: allocationId,
        // TODO: https://github.com/kindestcom/automator-web/issues/193
        // Currently, we don't allow to cover processing fees for Chariot donations. However, it may be used in the
        // future. The logic is already implemented both in the backend and frontend. So in case we need it, we can use it.
        // donorCoversProcessingFees: coverProcessingFees,
        workflowSessionId,
        userFriendlyId,
        fundId,
        // TODO: https://github.com/kindestcom/automator-web/issues/193
        // Currently, we don't allow to cover processing fees for Chariot donations. However, it may be used in the
        // future. The logic is already implemented both in the backend and frontend. So in case we need it, we can use it.
        // amount: coverProcessingFees
        //  ? addProcessingFees(amount, 0.029, 30)
        //  : amount,
        amount,
        email: email,
        recaptchaResponse,
        address: {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          // TODO: collect address here or from Chariot? | ...address,
        },
        anonymous,
      }

      if (showDedication) {
        input.dedication = {
          type: dedication.type,
          firstName: dedication.firstName,
          lastName: dedication.lastName,
        }

        if (dedication.notification?.email) {
          input.dedication.notification = dedication.notification
        }
      }

      const { data } = await createOrderCharitoMutation({
        variables: { input },
      })

      if (data?.createOrderChariot.success && data.createOrderChariot.id) {
        onSuccess(amount, data.createOrderChariot.id)
      } else if (data?.createOrderChariot.error) {
        onError(data?.createOrderChariot.error)
      }
    } catch (error: any) {
      throw new Error(
        (error as any)?.graphQLErrors?.[0]?.message ?? 'Something Went Wrong'
      )
    }
  }

  return createOrderChariot
}
