import styled from 'styled-components'
import { colors } from '~/styles/colors'

export const DotsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.625rem',
  justifyContent: 'center',
  alignItems: 'center',
})

export const Dot = styled.div<{ isActive: boolean }>(
  {
    display: 'block',
    alignItems: 'center',
    width: '0.625rem',
    height: '0.625rem',
    borderRadius: '50%',
    //   border: `1px solid ${colors.slateGray}`,
  },
  props =>
    props.isActive
      ? {
          backgroundColor: colors.primary.default,
        }
      : {
          backgroundColor: colors.white,
          border: `1px solid ${colors.slateGray}`,

          //   '&:hover': {
          //     boxShadow: `0 0 0 1px ${colors.slateGray}`,
          //   },
        }
)
