import { Meta } from '~/components/typography'

import { Loader, LoaderWrapper } from './style'

export const Loading = () => {
  return (
    <LoaderWrapper>
      <Loader>
        <div />
        <div />
        <div />
      </Loader>
      <Meta marginTop={'1rem'} regular>
        Loading data...
      </Meta>
    </LoaderWrapper>
  )
}
