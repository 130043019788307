import { PaymentElement } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import { CheckboxField } from '~/components/checkbox';
import { Box } from '~/components/layout';
import { PageNonprofit } from '~/types/graphql';

import IconInfo from '../../assets/icon-info.svg';
import { Values } from '../../hooks/use-form';
import { PaymentMethod } from '../../types';
import { FormSection } from '../form-section';
import { InfoTooltip } from '../tooltip';
import IconCard from './assets/card-icon.svg';

type Props = {
  donorCoversFees: boolean
  nonprofit: PageNonprofit
  onPaymentMethodChange: (paymentMethod: PaymentMethod) => void
}

export const BillingDetails = ({
  donorCoversFees,
  nonprofit,
  onPaymentMethodChange,
}: Props) => {
  const { setFieldValue } = useFormikContext<Values>()

  return (
    <FormSection icon={<IconCard />} heading="Method of Payment">
      <PaymentElement
        id="payment-element"
        onChange={(e: any) => {
          const method =
            e.value.type == 'us_bank_account'
              ? PaymentMethod.Ach
              : e.value.type == 'card'
              ? PaymentMethod.Card
              : PaymentMethod.Other
          onPaymentMethodChange(method)
          setFieldValue('paymentMethod', method)
        }}
        options={{
          layout: 'tabs',
        }}
      />

      {donorCoversFees ? (
        <Box display={'flex'} alignItems={'center'} marginTop={28}>
          <CheckboxField
            marginRight={'0.5rem'}
            label={`I will cover fees so ${nonprofit.name} will receive 100% of my donation.`}
            name="coverProcessingFees"
          />
          <InfoTooltip
            tooltipText={`Fees are Platform and Payment Processing Fees.`}
          >
            <IconInfo />
          </InfoTooltip>
        </Box>
      ) : (
        <></>
      )}
    </FormSection>
  )
}
