import { FunctionComponent } from 'react'
import { Dot, DotsWrapper } from './style'

interface StepperProps {
  stepsAmount: number
  activeStepIndex: number
}

const Stepper: FunctionComponent<StepperProps> = props => {
  const { stepsAmount, activeStepIndex } = props

  return (
    <DotsWrapper>
      {Array(stepsAmount)
        .fill(1)
        .map((_item, index) => (
          <Dot isActive={activeStepIndex >= index} key={index} />
        ))}
    </DotsWrapper>
  )
}

export default Stepper
