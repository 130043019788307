import { boolean, number, object, string, StringSchema } from 'yup'
import {
  DedicationInput,
  DedicationNotificationInput,
  DedicationType,
  DonationRecurrenceInterval,
  OrderCalculationResult,
} from '~/types/graphql'

import { UNITED_STATES } from '../components/donor-information/hooks/use-country-options'
import { PaymentMethod } from '../types'

const schema = object({
  email: string()
    .email('Email is invalid')
    .required('Email is required')
    .trim(),
  amount: number().required('Donation amount is required'),
  firstName: string().required('First name is required').trim(),
  lastName: string().required('Last name is required').trim(),
  phone: string(),
  anonymous: boolean().required(),
  showDedication: boolean().required(),
  showAddress: boolean().required(),
  coverProcessingFees: boolean().required(),
  paymentMethod: string()
    .oneOf([PaymentMethod.Card, PaymentMethod.Ach, PaymentMethod.Other])
    .defined(),
  paymentFrequency: string()
    .oneOf([
      DonationRecurrenceInterval.None,
      DonationRecurrenceInterval.Monthly,
      DonationRecurrenceInterval.Quarterly,
      DonationRecurrenceInterval.Yearly,
    ])
    .defined(),
  allocationId: string(),
  address: object().when('showAddress', {
    is: true,
    then() {
      return object({
        addressLine1: string().required('Street Address is required').trim(),
        addressLine2: string().trim(),
        city: string().required('City is required').trim(),
        country: string().required('Country is required').trim(),
        zip: string().required('ZIP is required').trim(),
        state: string().required('State is required').trim(),
      })
    },
    otherwise() {
      return object()
    },
  }),
  dedication: object<DedicationInput>().when('showDedication', {
    is: true,
    then() {
      return object<DedicationInput>({
        firstName: string().required("Honoree's first name is required").trim(),
        lastName: string().required("Honoree's last name is required").trim(),
        type: string()
          .oneOf([DedicationType.InHonor, DedicationType.InMemory])
          .required(),
        notification: object<DedicationNotificationInput>()
          .shape(
            {
              email: string()
                .email('Email is invalid')
                .when(['firstName', 'lastName'], {
                  is: (firstName: string, lastName: string) =>
                    firstName || lastName,
                  then() {
                    return string()
                      .email('Must be a valid email address')
                      .required('Email is required')
                      .trim()
                  },
                  otherwise() {
                    return string().email()
                  },
                }) as StringSchema<string>,
              firstName: string().when(['email', 'lastName'], {
                is: (email: string, lastName: string) => email || lastName,
                then() {
                  return string().required('First name is required').trim()
                },
                otherwise() {
                  return string()
                },
              }) as StringSchema<string>,
              lastName: string().when(['firstName', 'email'], {
                is: (firstName: string, email: string) => firstName || email,
                then() {
                  return string().required('Last name is required').trim()
                },
                otherwise() {
                  return string()
                },
              }) as StringSchema<string>,
              message: string(),
            },
            [
              ['email', 'firstName'],
              ['email', 'lastName'],
              ['firstName', 'lastName'],
            ]
          )
          .defined(),
      })
    },
    otherwise() {
      return object()
    },
  }),
}).defined()

export type Values = {
  email: string
  firstName: string
  lastName: string
  amount: number
  phone: string
  tip: number
  allocationId: string
  showDedication: boolean
  showAddress: boolean
  address: {
    addressLine1: string
    addressLine2: string
    city: string
    country: string
    zip: string
    state: string
  }
  coverProcessingFees: boolean
  paymentMethod: PaymentMethod
  paymentFrequency: DonationRecurrenceInterval
  anonymous: boolean
  dedication: {
    firstName: string
    lastName: string
    type: DedicationType
    notification: {
      email: string
      firstName: string
      lastName: string
      message: string
    }
  }
  orderCalculation: {
    withCoveringFees: OrderCalculationResult
    withoutCoveringFees: OrderCalculationResult
    tip: number
  }
  switchedToMonthly: boolean
}
// TODO: schema .validateSync possible fix?
// export type Values = Required<InferType<typeof schema>>

export const useForm = (
  defaultDonorCoverFeesValue: boolean,
  isAddressRequired: boolean,
  defaultAllocationId?: string
) => {
  const initialValues: Values = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    amount: 0,
    tip: 0,
    allocationId: defaultAllocationId ?? '',
    showDedication: false,
    coverProcessingFees: defaultDonorCoverFeesValue,
    paymentMethod: PaymentMethod.Card,
    paymentFrequency: DonationRecurrenceInterval.None,
    anonymous: false,
    showAddress: isAddressRequired,
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: UNITED_STATES,
      zip: '',
      state: '',
    },
    dedication: {
      firstName: '',
      lastName: '',
      type: DedicationType.InHonor,
      notification: {
        email: '',
        firstName: '',
        lastName: '',
        message: '',
      },
    },
    orderCalculation: {
      withCoveringFees: { net: 0, gross: 0, fee: 0 },
      withoutCoveringFees: { net: 0, gross: 0, fee: 0 },
      tip: 0,
    },
    switchedToMonthly: false,
  }

  return { initialValues, schema } as const
}
