import { captureException } from '@sentry/react'
import { Form, Formik, FormikHelpers } from 'formik'
import dynamic from 'next/dynamic'
import router from 'next/router'
import { useEffect, useState } from 'react'
import { routes } from '~/config/routes'
import { useToasts } from '~/context/toasts'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import { useRecaptcha } from '~/hooks/use-recaptcha'
import { ChariotError } from '~/types/graphql'
import { FocusError } from '~/utils/focus-error'

import { useModal } from '../modal'
import { Dedication } from '../payment-form/components/dedication'
import { useCreateOrderChariot } from '../payment-form/hooks/use-create-order-chariot'
import { Values } from '../payment-form/hooks/use-form'
import { ChariotDonorInformation } from './components/chariot-donor-information/idnex'
import { ChariotSummary } from './components/chariot-summary'
import { ChariotFormValues, useForm } from './hooks/use-form'

type Props = {
  page: PageBySlugQuery['pageBySlug']
  onSuccess: (amount: number, orderId: string) => void
  fundraiserId?: string
}

export type FeesProps = { variable: number; fixed: number }
const ModalConfirm = dynamic(() => import('~/components/modal-confirm'))

export const ChariotPaymentForm = ({
  page,
  onSuccess,
  fundraiserId,
}: Props) => {
  const toasts = useToasts()
  const recaptcha = useRecaptcha()
  const { id, allocations, nonprofit, pageFeatures, slug } = page
  const { initialValues, schema } = useForm(
    pageFeatures.addressForm,
    nonprofit.defaultAllocation?.id
  )
  const [sessionStorageData, setSessionStorageData] = useState({
    amount: 0,
    fundId: '',
    userFriendlyId: '',
    workflowSessionId: '',
  })

  useEffect(() => {
    if (sessionStorage.chariotSessionData) {
      setSessionStorageData(JSON.parse(sessionStorage.chariotSessionData))
    }
  }, [])

  const onError = (error: ChariotError) => {
    if (error === ChariotError.Overcharged) {
      modalInfo.show()
    } else {
      toasts.error(`Something went wrong. Error message: ${error}`)
    }
  }

  const createOrderChariot = useCreateOrderChariot({
    pageId: id,
    onSuccess,
    onError,
    fundraiserId,
  })

  const modalInfo = useModal()

  const handleSubmit = async (
    values: ChariotFormValues,
    helpers: FormikHelpers<ChariotFormValues>
  ) => {
    const { setSubmitting } = helpers
    try {
      const recaptchaResponse = await recaptcha.execute()
      await createOrderChariot(
        values as Values,
        sessionStorageData,
        recaptchaResponse
      )
    } catch (error: any) {
      toasts.error(error.message)
      captureException(error)
      recaptcha.reset()
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <>
        <FocusError />
        <Form>
          <ChariotDonorInformation
            allocations={allocations}
            features={pageFeatures}
          />
          <Dedication />
          <ChariotSummary amount={sessionStorageData.amount} />
        </Form>
        <ModalConfirm
          heading="🤖 Beep-Boop!"
          message={`Your DAF balance isn’t quite enough. Please check your DAF balance.`}
          note="Now you will be returned to the Fundraising page. Your progress will be lost and any linked funds within the Chariot Donor Advised Fund form will be unlinked."
          submitLabel="Continue"
          hideCancel
          onSubmit={() =>
            router.replace(routes.fundraisingPage.home(slug!, fundraiserId).as)
          }
          {...modalInfo}
        />
      </>
    </Formik>
  )
}
