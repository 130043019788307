import { boolean, object, string, StringSchema } from 'yup'
import { UNITED_STATES } from '~/components/payment-form/components/donor-information/hooks/use-country-options'
import {
  DedicationInput,
  DedicationNotificationInput,
  DedicationType,
} from '~/types/graphql'

const schema = object()
  .shape({
    email: string()
      .email('Email is invalid')
      .required('Email is required')
      .trim(),
    firstName: string().required('First name is required').trim(),
    lastName: string().required('Last name is required').trim(),
    // TODO: https://github.com/kindestcom/automator-web/issues/193
    // Currently, we don't allow to cover processing fees for Chariot donations. However, it may be used in the
    // future. The logic is already implemented both in the backend and frontend. So in case we need it, we can use it.
    coverProcessingFees: boolean().required(),
    phone: string().trim(),
    allocationId: string(),
    anonymous: boolean().required(),
    showDedication: boolean().required(),
    showAddress: boolean().required(),
    address: object().when('showAddress', {
      is: true,
      then() {
        return object({
          addressLine1: string().required('Street Address is required').trim(),
          addressLine2: string().trim(),
          city: string().required('City is required').trim(),
          country: string().required('Country is required').trim(),
          zip: string().required('ZIP is required').trim(),
          state: string().required('State is required').trim(),
        })
      },
      otherwise() {
        return object()
      },
    }),
    dedication: object<DedicationInput>().when('showDedication', {
      is: true,
      then() {
        return object<DedicationInput>({
          firstName: string()
            .required("Honoree's first name is required")
            .trim(),
          lastName: string().required("Honoree's last name is required").trim(),
          type: string()
            .oneOf([DedicationType.InHonor, DedicationType.InMemory])
            .required(),
          notification: object<DedicationNotificationInput>()
            .shape(
              {
                email: string()
                  .email('Email is invalid')
                  .when(['firstName', 'lastName'], {
                    is: (firstName: string, lastName: string) =>
                      firstName || lastName,
                    then() {
                      return string()
                        .email('Must be a valid email address')
                        .required('Email is required')
                        .trim()
                    },
                    otherwise() {
                      return string().email()
                    },
                  }) as StringSchema<string>,
                firstName: string().when(['email', 'lastName'], {
                  is: (email: string, lastName: string) => email || lastName,
                  then() {
                    return string().required('First name is required').trim()
                  },
                  otherwise() {
                    return string()
                  },
                }) as StringSchema<string>,
                lastName: string().when(['firstName', 'email'], {
                  is: (firstName: string, email: string) => firstName || email,
                  then() {
                    return string().required('Last name is required').trim()
                  },
                  otherwise() {
                    return string()
                  },
                }) as StringSchema<string>,
                message: string().defined(),
              },
              [
                ['email', 'firstName'],
                ['email', 'lastName'],
                ['firstName', 'lastName'],
              ]
            )
            .defined(),
        })
      },
    }),
  })
  .defined()

export type ChariotFormValues = {
  email: string
  firstName: string
  lastName: string
  allocationId: string
  phone: string
  anonymous: boolean
  showDedication: boolean
  showAddress: boolean
  address: {
    addressLine1: string
    addressLine2: string
    city: string
    country: string
    zip: string
    state: string
  }
  coverProcessingFees: boolean
  dedication: {
    firstName: string
    lastName: string
    type: DedicationType
    notification: {
      email: string
      firstName: string
      lastName: string
      message: string
    }
  }
}

export const useForm = (
  isAddressRequired: boolean,
  defaultAllocationId?: string
) => {
  const initialValues: ChariotFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    allocationId: defaultAllocationId ?? '',
    phone: '',
    anonymous: false,
    showDedication: false,
    showAddress: isAddressRequired,
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: UNITED_STATES,
      zip: '',
      state: '',
    },
    // TODO: https://github.com/kindestcom/automator-web/issues/193
    // Currently, we don't allow to cover processing fees for Chariot donations. However, it may be used in the
    // future. The logic is already implemented both in the backend and frontend. So in case we need it, we can use it.
    coverProcessingFees: false,
    dedication: {
      firstName: '',
      lastName: '',
      type: DedicationType.InHonor,
      notification: {
        email: '',
        firstName: '',
        lastName: '',
        message: '',
      },
    },
  }
  return { initialValues, schema } as const
}
