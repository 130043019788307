import {
  PaymentRequestButtonElement,
  useElements,
} from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'
import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { Amount } from '~/components/amount'
import { Button } from '~/components/button'
import { CheckboxField } from '~/components/checkbox'
import { Box, Flex } from '~/components/layout'
import { Meta } from '~/components/typography'
import { colors } from '~/styles/colors'
import { DonationRecurrenceInterval } from '~/types/graphql'

import { Values } from '../../hooks/use-form'
import { PaymentMethod } from '../../types'

type Props = {
  paymentMethod: PaymentMethod
  paymentRequest?: PaymentRequest
}

export const Summary = ({ paymentMethod, paymentRequest }: Props) => {
  const { isSubmitting, values, isValid, submitForm } =
    useFormikContext<Values>()
  const elements = useElements()

  const activeFees = useMemo(() => {
    return values.coverProcessingFees
      ? values.orderCalculation.withCoveringFees
      : values.orderCalculation.withoutCoveringFees
  }, [values.coverProcessingFees, values.orderCalculation])

  const renderPaymentButton = () => {
    if (paymentRequest && paymentMethod === PaymentMethod.Other) {
      return (
        <PaymentRequestButtonElement
          onClick={event => {
            event.preventDefault()
            if (isValid) {
              submitForm().catch(() => {})
            }
          }}
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'donate',
                height: '48px',
              },
            },
          }}
        />
      )
    }

    return (
      <Button
        disabled={!isValid}
        onClick={() => {
          if (isValid) {
            submitForm().catch(() => {})
          }
        }}
        width="100%"
        loading={isSubmitting}
      >
        Donate Now
      </Button>
    )
  }

  useEffect(() => {
    if (elements) {
      elements.update({
        amount: activeFees.gross,
        setup_future_usage:
          values.paymentFrequency == DonationRecurrenceInterval.None &&
          !values.switchedToMonthly
            ? undefined
            : 'off_session',
      })
    }
  }, [
    activeFees,
    elements,
    values.coverProcessingFees,
    values.paymentFrequency,
    values.switchedToMonthly,
  ])

  return (
    <Box
      padding={['30px 20px 40px', '30px 20px 40px', 40]}
      style={{ background: '#F7F7F7' }}
    >
      <Box marginBottom="0.5rem">
        <Flex justifyContent="space-between">
          <Meta regular>Donation</Meta>
          <Meta regular>
            <Amount>{activeFees.gross}</Amount>
          </Meta>
        </Flex>
      </Box>

      <Box height={1} background={colors.whiteFog} marginY={16} />
      {values.paymentFrequency !== DonationRecurrenceInterval.Monthly && (
        <Box marginBottom="1rem">
          <CheckboxField
            label="Make my gift go further. Give monthly."
            name="switchedToMonthly"
          />
        </Box>
      )}

      <Flex justifyContent="space-between" marginBottom={16}>
        <Meta>Total Charge</Meta>
        <Meta>
          <Amount>{activeFees.gross}</Amount>
        </Meta>
      </Flex>
      {renderPaymentButton()}
    </Box>
  )
}
