import { Box } from 'reakit'
import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }

  40% {
    transform: scale(1);
    opacity: 1;
  }
`

export const Loader = styled.div`
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: var(--kindest-primary-default);
    border-radius: 100%;
    animation: ${animation} 1.4s infinite ease-in-out both;

    :nth-child(1) {
      animation-delay: -0.32s;
    }

    :nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`

export const LoaderWrapper = styled(Box)({
  padding: '32px 40px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})
