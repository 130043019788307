import { captureException } from '@sentry/react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useEffect } from 'react'
import { useToasts } from '~/context/toasts'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import { useRecaptcha } from '~/hooks/use-recaptcha'
import { OnlinePaymentMethodType } from '~/types/graphql'
import { FocusError } from '~/utils/focus-error'

import { BillingDetails } from './components/billing-details'
import { Dedication } from './components/dedication'
import { DonorInformation } from './components/donor-information'
import { Loading } from './components/loading'
import { Summary } from './components/summary'
import { useGetOrderFeesQuery } from './graphql/get-order-fees'
import { useCreateOrder } from './hooks/use-create-order'
import { useForm, Values } from './hooks/use-form'
import { usePaymentRequest } from './hooks/use-payment-request'
import { PaymentMethod } from './types'

type Props = {
  page: PageBySlugQuery['pageBySlug']
  onSuccess: (amount: number, orderId: string) => void
  fundraiserId?: string
}
export type OrderFeesInput = {
  amount: number
  pageId: string
  paymentMethod: OnlinePaymentMethodType
  tip: number
}
export type FeesProps = { variable: number; fixed: number }

export const KindestPaymentForm = ({
  page,
  onSuccess,
  fundraiserId,
}: Props) => {
  const {
    id,
    allocations,
    nonprofit,
    defaultDonorCoverFeesValue,
    pageFeatures,
  } = page
  const toasts = useToasts()
  const recaptcha = useRecaptcha()
  const storage = JSON.parse(sessionStorage.formData)

  const { data, loading, fetchMore } = useGetOrderFeesQuery({
    variables: {
      input: {
        amount: storage.amount,
        pageId: id,
        paymentMethod: OnlinePaymentMethodType.Card,
        tip: 0,
      },
    },
  })

  // INFO: temporary workaround - propagate to BE
  const { initialValues, schema } = useForm(
    defaultDonorCoverFeesValue,
    pageFeatures.addressForm,
    nonprofit.defaultAllocation?.id
  )

  const createOrder = useCreateOrder({
    pageId: id,
    onSuccess,
    fundraiserId,
    nonprofit,
  })

  const handleSubmit = async (
    values: Values,
    helpers: FormikHelpers<Values>
  ) => {
    const { setSubmitting } = helpers

    try {
      const recaptchaResponse = await recaptcha.execute()
      await createOrder(recaptchaResponse, values)
    } catch (error: any) {
      toasts.error(error.message)
      captureException(error)
      recaptcha.reset()
      setSubmitting(false)
    }
  }

  const handlePaymentMethod = (values: Values) => async () => {
    try {
      const recaptchaResponse = await recaptcha.execute()
      await createOrder(recaptchaResponse, values)
    } catch (error: any) {
      toasts.error(error.message)
      captureException(error)
      recaptcha.reset()
    }
  }

  return (
    <>
      {!loading && data ? (
        <Formik
          initialValues={{
            ...initialValues,
            ...storage,
            orderCalculation: data.orderCalculation,
            allocationId: nonprofit.defaultAllocation?.id ?? '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            const getNewAmount = async (selectedMethod: PaymentMethod) => {
              const paymentMethod =
                selectedMethod == PaymentMethod.Ach
                  ? OnlinePaymentMethodType.BankAccount
                  : OnlinePaymentMethodType.Card
              const { data } = await fetchMore({
                variables: {
                  input: {
                    amount: storage.amount,
                    pageId: id,
                    paymentMethod: paymentMethod,
                    tip: 0,
                  },
                },
              })
              setFieldValue('orderCalculation', data.orderCalculation)
            }

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { paymentRequest } = usePaymentRequest({
              values,
              onPaymentMethod: handlePaymentMethod(values),
            })

            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (values.paymentMethod == PaymentMethod.Other) {
                recaptcha.execute()
              }
            }, [values.paymentMethod])

            return (
              <>
                <FocusError />
                <Form>
                  <DonorInformation
                    allocations={allocations}
                    nonprofit={nonprofit}
                    features={pageFeatures}
                  />
                  <Dedication />
                  <BillingDetails
                    donorCoversFees={pageFeatures.donorCoversFees}
                    nonprofit={nonprofit}
                    onPaymentMethodChange={paymentMethod =>
                      getNewAmount(paymentMethod)
                    }
                  />
                  <Summary
                    paymentMethod={values.paymentMethod}
                    paymentRequest={paymentRequest}
                  />
                </Form>
              </>
            )
          }}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  )
}
