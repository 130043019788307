import { useRouter } from 'next/router'
import { getQueryParameter } from '~/utils/get-query-param'
import { UtmDataInput } from '~/types/graphql'

export const useUtmParse = (): UtmDataInput => {
  const { query } = useRouter()

  // We don't have to check values stored here, because we're normalizing
  // them on the server side.
  return {
    medium: getQueryParameter(query, 'utm_medium'),
    source: getQueryParameter(query, 'utm_source'),
    campaign: getQueryParameter(query, 'utm_campaign'),
    content: getQueryParameter(query, 'utm_content'),
  }
}
