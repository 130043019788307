import { Box, Flex } from '~/components/layout'
import { Heading } from '~/components/typography'
import { colors } from '~/styles/colors'

type Props = {
  icon: React.ReactNode
  heading: string
  children: React.ReactNode
}

export const FormSection = ({ icon, heading, children }: Props) => {
  return (
    <Box padding={[20, 20, 40]} borderBottom={colors.border}>
      <Flex alignItems="center" marginBottom={32}>
        {icon}
        <Heading level={5} marginLeft={16}>
          {heading}
        </Heading>
      </Flex>
      {children}
    </Box>
  )
}
