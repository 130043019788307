import { useFormikContext } from 'formik'
import { useMemo } from 'react'
import { CheckboxField } from '~/components/checkbox'
import { Box, Grid } from '~/components/layout'
import { SelectField } from '~/components/select'
import { TextInputField } from '~/components/text-input'
import { Text } from '~/components/typography'

import { Values } from '../../hooks/use-form'
import { DoubleTheDonation } from '../double-the-donation'
import { FormSection } from '../form-section'
import IconPerson from './assets/icon-person.svg'
import { UNITED_STATES, useCountryOptions } from './hooks/use-country-options'
import { useStateOptions } from './hooks/use-state-options'

import type {
  PageFeatures,
  PageNonprofit,
  PublicAllocation,
} from '~/types/graphql'
type Props = {
  allocations?: PublicAllocation[]
  nonprofit: PageNonprofit
  features: PageFeatures
}

export const DonorInformation = ({
  nonprofit,
  allocations,
  features,
}: Props) => {
  const { values } = useFormikContext<Values>()
  const { allocations: showAllocations, addressForm: showAddress } = features

  const allocationOptions = useMemo(() => {
    if (!allocations) {
      return [{ value: '', label: 'Where it is needed most' }]
    }

    return [{ value: '', label: 'Where it is needed most' }].concat(
      allocations.map(allocation => ({
        label: allocation.name,
        value: allocation.id,
      }))
    )
  }, [allocations])

  const countryOptions = useCountryOptions()
  const stateOptions = useStateOptions()
  const isUnitedStates = values.address.country === UNITED_STATES

  return (
    <FormSection icon={<IconPerson />} heading="Donor Information">
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={20}>
        <TextInputField
          label="First Name"
          name="firstName"
          autoComplete="given-name"
        />
        <TextInputField
          label="Last Name"
          name="lastName"
          autoComplete="family-name"
        />
        <TextInputField
          label="Email Address"
          name="email"
          gridColumn={[null, '1 / 3']}
          autoComplete="email"
        />
        {showAddress ? (
          <>
            <TextInputField
              label="Street Address"
              name="address.addressLine1"
              autoComplete="billing address-line1"
            />
            <TextInputField
              label="Apartment, suite, etc."
              name="address.addressLine2"
              autoComplete="billing address-line2"
              optional
            />
            <TextInputField
              label="City"
              name="address.city"
              autoComplete="billing address-level2"
            />
            <SelectField
              label="Country"
              name="address.country"
              options={countryOptions}
              autoComplete="billing country-name"
            />
            <TextInputField
              label={isUnitedStates ? 'ZIP' : 'Postal Code'}
              name="address.zip"
              autoComplete="billing postal-code"
            />
            {isUnitedStates && (
              <SelectField
                label="State"
                name="address.state"
                options={stateOptions}
                placeholder="Select a State"
                autoComplete="billing address-level1"
              />
            )}
          </>
        ) : (
          <></>
        )}
        <TextInputField
          label="Phone"
          name="address.phone"
          optional
          autoComplete="tel"
          gridColumn={[null, '1 / 3']}
        />
        {nonprofit.doubleTheDonationPublicKey && (
          <Box gridColumn={[null, '1 / 3']}>
            <DoubleTheDonation
              publicKey={nonprofit.doubleTheDonationPublicKey ?? ''}
            />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gridColumn={[null, '1 / 3']}>
          {showAllocations ? (
            <>
              <Text bold marginBottom="0.25rem">
                Apply Your Gift to
              </Text>
              <Box marginBottom="1.5rem">
                <SelectField
                  label=""
                  name="allocationId"
                  options={allocationOptions}
                  placeholder="Where it is needed most"
                  gridColumn={[null, '1 / 3']}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Grid gridRowGap={'1rem'} gridColumn={[null, '1 / 3']}>
          <CheckboxField name="anonymous" label="Make my donation anonymous" />
          <CheckboxField
            name="showDedication"
            label="Dedicate my donation in honor or in memory of someone"
          />
        </Grid>
      </Grid>
    </FormSection>
  )
}
