import styled from 'styled-components'
import { colors } from '~/styles/colors'

export const TooltipButton = styled.button({
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  color: colors.slateGray,

  '&:hover': {
    color: colors.dark,
  },
})
