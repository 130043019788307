import { PropsWithChildren, useState } from 'react'
import { Meta } from '~/components/typography'
import { AccessibleIcon } from '~/system/accessible-icon'
import { Tooltip } from '~/system/tooltip'

import { TooltipButton } from './style'

type Props = {
  tooltipText: string
}

export const InfoTooltip = ({
  tooltipText,
  children,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(false)
  // TODO: implement the ! icon to the tooltip if possible
  return (
    <Tooltip delayDuration={100} open={open} onOpenChange={setOpen}>
      <TooltipButton onClick={() => setOpen(o => !o)}>
        <AccessibleIcon label="Tooltip">{children}</AccessibleIcon>
      </TooltipButton>
      <Meta
        regular
        style={{ color: '#fff', maxWidth: '18rem', lineHeight: '1.5rem' }}
      >
        {tooltipText}
      </Meta>
    </Tooltip>
  )
}
